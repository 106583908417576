import Vue from "vue";
import Vuex from "vuex";
import router from "./router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    salesData: null,
    stateTest: "dfg",
    newDate: null,
  },
  getters: {
    token() {
      return this.state.token;
    },
  },
  mutations: {
    setTokenInState(state, token) {
      state.token = token;
    },
    setSalesData(state, salesData) {
      state.salesData = salesData;
    },
    setNewDate(state, newDate) {
      state.newDate = newDate;
    },
    logOut(state) {
      state.salesData = null;
      state.token = null;
      localStorage.removeItem("token");
    },
  },
  actions: {
    setTokenInState({ commit }) {
      let user = JSON.parse(localStorage.getItem("token"));
      let token;
      if (user) {
        token = user;
      } else {
        token = null;
      }
      commit("setTokenInState", token);
    },
    getSalesData({ state }, http) {
      // return
      // body: { date: "2020-12-01" },
      // Post går ikke nedenfor, bare get. Dette er nok proisp sin issue
      // get går , men da uten body, må da sende som param
      //.get("http://localhost:8082/cake/api/salesData", {
      return http.ax
        .get("https://api.avonovasales.no/cake/api/salesData", {
          params: { date: http.date },
          method: "GET",
          headers: {
            "x-access-token": state.token,
          },
        })
        .catch(() => {
          this.commit("logOut");
          router.push({ name: "Login" });
        });
    },
  },
});
