<!-- Avonova Solutions AS. All rights reserved. -->
<template>
  <v-app ref="app">
    <v-main><router-view></router-view></v-main>
  </v-app>
</template>
<script>
export default {
  name: "app",
  data() {
    return {};
  },
  created() {
    if (!this.isTokenSet) this.$store.dispatch("setTokenInState");
    this.$store.commit("setNewDate", new Date().toISOString().substr(0, 10));
    this.$router.push({
      name: "Home",
      params: { date: new Date().toISOString().substr(0, 10) },
    });
  },
  computed: {
    isTokenSet() {
      return this.$store.state.token;
    },
  },
};
</script>

<style lang="scss">
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.compat.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;0,900;1,800&display=swap");

#app {
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  color: #00211b;
}
.vollkorn {
  font-family: "Vollkorn", serif;
}
#top-bar-one {
  background: #00211b;
  font-weight: 800;
  font-size: 2.3em;
}
</style>
