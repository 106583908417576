import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import "./styles/app.scss";
import axios from "axios";
import VueGtag from "vue-gtag";
import store from "./store";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.use(
  VueGtag,
  {
    config: { id: "UA-184601614-1" },
  },
  router
);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
