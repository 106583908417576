import Vue from "vue";
import Vuetify, {
  VApp,
  VContainer,
  VBtn,
  VBreadcrumbs,
  VList,
  VDataTable,
  VMenu,
} from "vuetify/lib";
import "@fortawesome/fontawesome-free/css/all.css";
import "@mdi/font/css/materialdesignicons.css";
Vue.use(Vuetify, {
  components: {
    VApp,
    VContainer,
    VBtn,
    VBreadcrumbs,
    VList,
    VDataTable,
    VMenu,
  },
});

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg", // mdi?
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        avonovaDarkGreen: "#00211b",
        avonovaMidGreen: "#005646",
        avonovaSignalGreen: "#27DDBB",
        primary: "#028655",
        secondary: "#B4E0D1",
        accent: "#FFBE83",
        error: "#BB1D1D",
        gray: "#F0F4F3",
        lightgray: "#E0E0E0",
        darkgray: "#828282",
        darkergray: "#444444",
        black: "#111111",
        white: "#FFFFFF",
        red: "#FF0000",
        orange: "#B65F0F",
        avonovaRed: "#bb1d1d",
      },
    },
  },
  directives: {
    //Ripple,
  },
});
